import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  FunctionField,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  ListContextProvider,
} from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import { Card, makeStyles, Divider, Tabs, Tab } from '@material-ui/core';
import { stringify } from 'query-string';

import { DialogoConfirmacao } from '../../Components';
//import DialogoPrecificacao from '../../Components/Dialogos/DialogoPrecificacao';
import DialogoPrecificacao from '../../Components/Dialogos/DialogoMargeamento';
import { api } from '../../services';
import {
  round10,
  formatMoeda,
  getEstoqueLabel,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_FIXO,
  TIPO_IMPRESSAO_UN,
} from '../../utils';
import {
  STATUS_ATIVO,
  STATUS_INATIVO,
  STATUS_DELETADO,
} from '../../utils/constants';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Dialogo from './ContainerDialogoProduto';
import DialogoChangeRuptura from './DialogoChangeRuptura';
import DialogoChangeSearch from './DialogoChangeSearch';
import DialogoConferir from './DialogoConferir';
import DialogoShowProduto from './DialogoShow';
import Filter from './Filter';
import ValorField from './ValorField';

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

function getUnidade(produto) {
  if (produto.unidade.tipo === POR_UNIDADE) return 'Un';
  if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 'Kg';
  if (produto.unidade.tipo === POR_PESO_FIXO) {
    if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) return 'Un';
    return 'Kg';
  }
  return '';
}

function getFator(produto) {
  if (produto.unidade.tipo === POR_UNIDADE) return 1;
  if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 1;
  if (produto.unidade.tipo === POR_PESO_FIXO) {
    if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) return produto.peso_medio;
    return 1;
  }
  return '';
}

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const history = useHistory();
  const refDialogoConfirmacao = useRef(null);
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);

  const refDialogoCompra = useRef(null);
  const refDialogoConferir = useRef(null);
  const refDialogoChangeStatusSearch = useRef(null);
  const refDialogoShowProduto = useRef(null);

  const refDialogoChangeStatusRuptura = useRef(null);

  const refDialogoPrecificacao = useRef(null);

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate(1);
    }
  }

  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }

  function handleShow({ id }) {
    //history.push(`/produtos/${id}/show`);
    if (refDialogoShowProduto.current) {
      refDialogoShowProduto.current.handleOpen(id);
    }
  }

  function handleAtivar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Reativação de Produto',
        'Deseja realmente reativar este produto?',
        { id, codigo: 1 },
      );
    }
  }

  function handleDesativar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Desativação de Produto',
        'Deseja realmente desativar este produto?',
        { id, codigo: 2 },
      );
    }
  }

  function handleDeletar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Exclusão de Produto',
        'Deseja deletar permanentemente este produto?',
        { id, codigo: 3 },
      );
    }
  }

  async function ativar(id) {
    await api.put(`/produtos/status/ativar/${id}`);
    handleClose();
  }

  async function desativar(id) {
    await api.put(`/produtos/status/inativar/${id}`);
    handleClose();
  }

  async function deletar(id) {
    await api.put(`/fornecedores/status/deletar/${id}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        ativar(id);
        break;
      case 2:
        desativar(id);
        break;
      case 3:
        deletar(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  async function testeEstoque2(id) {
    const response = await api.get(`/testes/estoque2/${id}`);
  }

  function navegarCotacoes({ id }) {
    //testeEstoque2(id);
    history.push(`/cotacao-precos-produtos?produto_id=${id}`);
  }

  function navegarFluxoCaixa({ id }) {
    if (id) {
      history.push(`/relatorio-fluxo-caixa-produtos?produto_id=${id}`);
    }
  }

  function changeStatusSearch(record) {
    if (refDialogoChangeStatusSearch.current) {
      refDialogoChangeStatusSearch.current.handleOpen(record);
    }
  }

  function changeStatusRuptura(record) {
    if (refDialogoChangeStatusRuptura.current) {
      refDialogoChangeStatusRuptura.current.handleOpen(record);
    }
  }

  async function testeEstoque(id) {
    const response = await api.get(`/testes/estoque/${id}`);
  }

  function handlePrecificar(record) {
    //testeEstoque(record.id);
    if (refDialogoPrecificacao.current) {
      refDialogoPrecificacao.current.handleOpen(record);
    }
  }

  return (
    <>
      <Title title="Produtos" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <TextField source="id" label="Id" />
                <TextField source="nome" label="Descrição" />
                <ValorField source="preco_venda" label="Preço de Venda" />
                <FunctionField
                  label="Preço de Compra Médio"
                  render={(record) =>
                    record.precoCompraMedio > 0
                      ? `${formatMoeda(
                          record.precoCompraMedio * getFator(record),
                        )} / ${getUnidade(record)}`
                      : '-'
                  }
                />
                <FunctionField
                  label="Preço de Compra Médio Imposto"
                  render={(record) =>
                    record.precoCompraMedio > 0
                      ? `${formatMoeda(
                          record.precoCompraMedioImpostos * getFator(record),
                        )}  / ${getUnidade(record)}`
                      : '-'
                  }
                />
                <FunctionField
                  label="Lucro"
                  render={(record) =>
                    record.precoCompraMedio > 0
                      ? `${formatMoeda(
                          (record.preco_venda - record.precoCompraMedio) *
                            getFator(record),
                        )}  / ${getUnidade(record)}`
                      : '-'
                  }
                />
                <FunctionField
                  label="Margem de Lucro (%)"
                  render={(record) =>
                    record.precoCompraMedio > 0
                      ? `${round10(
                          ((record.preco_venda - record.precoCompraMedio) /
                            record.precoCompraMedio) *
                            100,
                          -2,
                        )}%`
                      : '-'
                  }
                />
                <FunctionField
                  label="Estoque"
                  render={(record) => getEstoqueLabel(record)}
                />
                {/*<TextField
                  source="pesoDisponivel"
                  label="Peso (Kg)"
                  sortable={false}
                />*/}
                <ReferenceField
                  label="Unidade"
                  source="unidade_id"
                  reference="unidades"
                  sortable={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ReferenceField
                  label="Categoria"
                  source="categoria_id"
                  reference="categorias"
                  sortable={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                  handleCotacoes={navegarCotacoes}
                  handleRelatorioEstoque={navegarFluxoCaixa}
                  changeStatusSearch={changeStatusSearch}
                  changeStatusRuptura={changeStatusRuptura}
                  handlePrecificar={handlePrecificar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <TextField source="id" label="Id" />
                <TextField source="nome" label="Descrição" />
                <ValorField source="preco_venda" label="Preço de Venda" />
                <FunctionField
                  label="Preço de Compra Médio"
                  render={(record) =>
                    record.precoCompraMedio > 0
                      ? `${formatMoeda(
                          record.precoCompraMedio * getFator(record),
                        )}  / ${getUnidade(record)}`
                      : '-'
                  }
                />
                <FunctionField
                  label="Preço de Compra Médio Imposto"
                  render={(record) =>
                    record.precoCompraMedio > 0
                      ? `${formatMoeda(
                          record.precoCompraMedioImpostos * getFator(record),
                        )}  / ${getUnidade(record)}`
                      : '-'
                  }
                />
                <FunctionField
                  label="Lucro"
                  render={(record) =>
                    record.precoCompraMedio > 0
                      ? `${formatMoeda(
                          (record.preco_venda - record.precoCompraMedio) *
                            getFator(record),
                        )}  / ${getUnidade(record)}`
                      : '-'
                  }
                />
                <FunctionField
                  label="Margem de Lucro (%)"
                  render={(record) =>
                    record.precoCompraMedio > 0
                      ? `${round10(
                          ((record.preco_venda - record.precoCompraMedio) /
                            record.precoCompraMedio) *
                            100,
                          -2,
                        )}%`
                      : '-'
                  }
                />
                {/*<FunctionField
                  label="Unidades"
                  render={(record) => getEstoqueLabel(record)}
                />
                <TextField
                  source="pesoDisponivel"
                  label="Peso (Kg)"
                  sortable={false}
                />*/}
                <ReferenceField
                  label="Unidade"
                  source="unidade_id"
                  reference="unidades"
                  sortable={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ReferenceField
                  label="Categoria"
                  source="categoria_id"
                  reference="categorias"
                  sortable={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                  handleCotacoes={navegarCotacoes}
                  handleRelatorioEstoque={navegarFluxoCaixa}
                  changeStatusSearch={changeStatusSearch}
                  changeStatusRuptura={changeStatusRuptura}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} />
      <DialogoConferir ref={refDialogoConferir} />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoChangeSearch ref={refDialogoChangeStatusSearch} />
      <DialogoShowProduto ref={refDialogoShowProduto} />
      <DialogoChangeRuptura ref={refDialogoChangeStatusRuptura} />
      <DialogoPrecificacao ref={refDialogoPrecificacao} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Produtos"
      filterDefaultValues={{
        status: STATUS_ATIVO,
        is_kit: false,
        is_caixa: false,
        ofertasProdutos: true,
      }}
      component="div"
    />
  </>
);

export default List;
