import React from 'react';

import {
  withStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  formatPeso,
  formatMoeda,
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
  formatPreco,
  formatPorcentagem,
} from '../../../../../../utils';

function formatPreco2(valor, casasDecimais = 2) {
  return valor !== null && valor !== undefined
    ? valor % 1 === 0
      ? valor
      : `${valor.toLocaleString('de-DE', {
          minimumFractionDigits: casasDecimais,
          maximumFractionDigits: casasDecimais,
        })}`
    : '';
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    //width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '7px',
    border: '1px solid black',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    width: 'min-content',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '7px',
    border: '1px solid black',
    width: 'min-content',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    //minWidth: 650,
  },
  paper: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'auto',
  },
}));

function getDadosKpi(dados) {
  const {
    valor,
    valorNota,
    produto,
    formatador = formatMoeda,
    isQtde = true,
    qtde,
  } = dados;

  const formatador2 = isQtde ? (value) => value : formatMoeda;

  if (produto) {
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        const unidade = valor ? formatador2(valor) : '-';
        let caixariaNivel1 = '-';
        let caixariaNivel2 = '-';
        let caixariaNivel3 = '-';
        let total = valor * qtde;
        total = isQtde ? '-' : total ? formatador2(total) : '-';
        const isFatorQtde = isQtde ? -1 : 1;

        caixariaNivel1 =
          produto.unidades_caixa_nivel_1 > 0 && valor > 0
            ? formatador2(valor * produto.unidades_caixa_nivel_1 ** isFatorQtde)
            : '-';

        caixariaNivel2 =
          produto.unidades_caixa_nivel_2 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2) **
                    isFatorQtde,
              )
            : '-';

        caixariaNivel3 =
          produto.unidades_caixa_nivel_3 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3) **
                    isFatorQtde,
              )
            : '-';
        return {
          peso: '-',
          unidade,
          nota: valorNota,
          caixariaNivel1,
          caixariaNivel2,
          caixariaNivel3,
          total,
        };
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        const peso = valor
          ? isQtde
            ? `${formatPeso(valor)}`
            : formatador2(valor)
          : '-';
        let caixariaNivel1 = '-';
        let caixariaNivel2 = '-';
        let caixariaNivel3 = '-';
        let total = valor * qtde;
        total = isQtde ? '-' : total ? formatador2(total) : '-';
        const isFatorQtde = isQtde ? -1 : 1;

        caixariaNivel1 =
          produto.unidades_caixa_nivel_1 > 0 && valor > 0
            ? formatador2(valor * produto.peso_caixa_nivel_1 ** isFatorQtde)
            : '-';

        caixariaNivel2 =
          produto.unidades_caixa_nivel_2 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2) **
                    isFatorQtde,
              )
            : '-';

        caixariaNivel3 =
          produto.unidades_caixa_nivel_3 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3) **
                    isFatorQtde,
              )
            : '-';
        return {
          peso,
          unidade: '-',
          nota: valorNota,
          caixariaNivel1,
          caixariaNivel2,
          caixariaNivel3,
          total,
        };
      }
      case POR_PESO_VARIAVEL: {
        return {
          peso: '-',
          unidade: '-',
          nota: '-',
          caixariaNivel1: '-',
          caixariaNivel2: '-',
          caixariaNivel3: '-',
          total: '-',
        };
      }
      case POR_PESO_FIXO: {
        const peso = valor
          ? isQtde
            ? `${formatPeso(valor * produto.peso_medio)}`
            : formatador2(valor)
          : '-';
        const unidade = valor
          ? isQtde
            ? `${valor}`
            : formatador2(valor * produto.peso_medio)
          : '-';
        let caixariaNivel1 = '-';
        let caixariaNivel2 = '-';
        let caixariaNivel3 = '-';
        let total = valor * produto.peso_medio * qtde;
        total = isQtde ? '-' : total ? formatador2(total) : '-';
        const isFatorQtde = isQtde ? -1 : 1;
        const isFatorPesoMedio = isQtde ? 1 : produto.peso_medio;

        caixariaNivel1 =
          produto.unidades_caixa_nivel_1 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 * isFatorPesoMedio) **
                    isFatorQtde,
              )
            : '-';

        caixariaNivel2 =
          produto.unidades_caixa_nivel_2 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    isFatorPesoMedio) **
                    isFatorQtde,
              )
            : '-';

        caixariaNivel3 =
          produto.unidades_caixa_nivel_3 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3 *
                    isFatorPesoMedio) **
                    isFatorQtde,
              )
            : '-';
        return {
          peso,
          unidade,
          nota: valorNota,
          caixariaNivel1,
          caixariaNivel2,
          caixariaNivel3,
          total,
        };
      }
    }
  }
  return {
    peso: '-',
    unidade: '-',
    nota: '-',
    caixariaNivel1: '-',
    caixariaNivel2: '-',
    caixariaNivel3: '-',
    total: '-',
  };
}

function RowKpi({
  label,
  name,
  valor,
  valorNota,
  produto,
  isQtde,
  qtde,
  isDadosComplementares,
  color = 'white',
  nRows = 0,
  labelGrupo = '',
  porcentagemCompra = '-',
  porcentagemVenda = '-',
}) {
  const {
    caixariaNivel1,
    caixariaNivel2,
    caixariaNivel3,
    nota,
    peso,
    unidade,
    total,
  } = getDadosKpi({
    valor,
    valorNota,
    produto,
    isQtde,
    qtde,
  });
  const style = {
    backgroundColor: color ? color : 'white',
  };
  return (
    <StyledTableRow key={name}>
      {nRows ? (
        <StyledTableCell
          component="th"
          scope="row"
          align="left"
          //style={style}

          rowSpan={nRows}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              writingMode: 'vertical-rl',
              transform: 'rotate(180deg)',
              display: 'flex',
              margin: '0 auto',
            }}
          >
            {labelGrupo}
          </span>
        </StyledTableCell>
      ) : null}
      <StyledTableCell component="th" scope="row" align="left" style={style}>
        {label}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center" style={style}>
        {peso}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center" style={style}>
        {unidade}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center" style={style}>
        {caixariaNivel1}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center" style={style}>
        {caixariaNivel2}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center" style={style}>
        {caixariaNivel3}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center" style={style}>
        {total}
      </StyledTableCell>
      {isDadosComplementares ? (
        <StyledTableCell
          component="th"
          scope="row"
          align="center"
          style={style}
        >
          {nota ? `${nota}` : '-'}
        </StyledTableCell>
      ) : null}
      <StyledTableCell component="th" scope="row" align="center" style={style}>
        {porcentagemCompra}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center" style={style}>
        {porcentagemVenda}
      </StyledTableCell>
    </StyledTableRow>
  );
}

function GrupoRowKpi({
  label,
  dataRows,
  produto,
  quantidade,
  isDadosComplementares,
}) {
  const nRows = dataRows.length;
  return (
    <>
      {dataRows.map((item, index) => (
        <RowKpi
          name={item.name}
          label={item.label}
          valor={item.valor}
          color={item.color}
          valorNota={item.valorNota}
          produto={produto}
          isQtde={item.isQtde}
          qtde={quantidade}
          isDadosComplementares={isDadosComplementares}
          nRows={index === 0 ? nRows : 0}
          labelGrupo={index === 0 ? label : ''}
          porcentagemCompra={item.porcentagemCompra}
          porcentagemVenda={item.porcentagemVenda}
        />
      ))}
    </>
  );
}

export default function Tabela({
  produto,
  quantidade,
  precoCompra,
  precoVendaPrecificado,
  dadosComplementaresNota,
  fatorPesoMedio,
  baseDeCalculo,
  margemContribuicao,
  margemLucroBruto,
  custoFinalProduto,
  ofertaZerarEstoque,
  ofertaJornal,
  repasseDoIpi,
  repasseDoFreteUnitario,
  repasseDeSt,
  repasseDeSeguros,
  repasseDeOutrasDespesas,
  precoAnteriorVenda,
  margemLucroBrutoPorecentagem,
}) {
  let unidades_caixa_nivel_1 = '';
  let unidades_caixa_nivel_2 = '';
  let unidades_caixa_nivel_3 = '';
  let peso_medio = '';

  let unidadesCaixariaNivel1 = 0;
  let unidadesCaixariaNivel2 = 0;
  let unidadesCaixariaNivel3 = 0;

  if (produto) {
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.peso_caixa_nivel_1 > 0) {
        unidades_caixa_nivel_1 = `(${formatPeso(produto.peso_caixa_nivel_1)})`;
        unidadesCaixariaNivel1 = `(${formatPeso(produto.peso_caixa_nivel_1)})`;
      }
      if (produto.unidades_caixa_nivel_2 > 0) {
        unidades_caixa_nivel_2 = `(${
          produto.unidades_caixa_nivel_2
        } Cx1 = ${formatPeso(
          produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2,
        )})`;
        unidadesCaixariaNivel2 = produto.unidades_caixa_nivel_2;
      }
      if (produto.unidades_caixa_nivel_3 > 0) {
        unidades_caixa_nivel_3 = `(${
          produto.unidades_caixa_nivel_3
        } Cx2 = ${formatPeso(
          produto.peso_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3,
        )})`;
        unidadesCaixariaNivel3 = produto.unidades_caixa_nivel_3;
      }
    } else if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.unidades_caixa_nivel_1 > 0) {
        unidades_caixa_nivel_1 = `(${produto.unidades_caixa_nivel_1} Un)`;
        unidadesCaixariaNivel1 = produto.unidades_caixa_nivel_1;
      }
      if (produto.unidades_caixa_nivel_2 > 0) {
        unidades_caixa_nivel_2 = `(${produto.unidades_caixa_nivel_2} Cx1 = ${
          produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
        } Un)`;
        unidadesCaixariaNivel2 = produto.unidades_caixa_nivel_2;
      }
      if (produto.unidades_caixa_nivel_3 > 0) {
        unidades_caixa_nivel_3 = `(${produto.unidades_caixa_nivel_3} Cx2 = ${
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3
        } Un)`;
        unidadesCaixariaNivel3 = produto.unidades_caixa_nivel_3;
      }
    } else if (produto.unidade.tipo === POR_PESO_FIXO) {
      peso_medio = `(${formatPeso(produto.peso_medio)})`;
      if (produto.unidades_caixa_nivel_1 > 0) {
        unidades_caixa_nivel_1 = `(${
          produto.unidades_caixa_nivel_1
        } Un = ${formatPeso(
          produto.unidades_caixa_nivel_1 * produto.peso_medio,
        )})`;
        unidadesCaixariaNivel1 = produto.unidades_caixa_nivel_1;
      }
      if (produto.unidades_caixa_nivel_2 > 0) {
        unidades_caixa_nivel_2 = `(${produto.unidades_caixa_nivel_2} Cx1 = ${
          produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
        } Un = ${formatPeso(
          produto.unidades_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.peso_medio,
        )})`;
        unidadesCaixariaNivel2 = produto.unidades_caixa_nivel_2;
      }
      if (produto.unidades_caixa_nivel_3 > 0) {
        unidades_caixa_nivel_3 = `(${produto.unidades_caixa_nivel_3} Cx2 = ${
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3
        } Un = ${formatPeso(
          produto.unidades_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3 *
            produto.peso_medio,
        )})`;
        unidadesCaixariaNivel3 = produto.unidades_caixa_nivel_3;
      }
    }
  }

  const color = 'blue';
  const corLetra = 'white';

  const nomeProduto = produto ? produto.nome : '-';
  const margemLucroBruto1 = formatPorcentagem(margemLucroBrutoPorecentagem);
  const baseDeCalculoIcms = formatMoeda(baseDeCalculo);
  const pp = produto
    ? produto.unidade.tipo === POR_PESO_FIXO
      ? `${formatPeso(produto.peso_medio)}`
      : '-'
    : '-';

  return (
    <Table aria-label="a dense table" style={{ width: 'min-content' }}>
      <TableHead>
        {/*<TableRow>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={5}
          />
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Nome do Produto:
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            colSpan={9}
          >
            {nomeProduto}
          </StyledTableCell>
        </TableRow>*/}
        {/*<TableRow>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Margem de Lucro Bruto (%)
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {margemLucroBruto1}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            colSpan={4}
          />
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={3}
            colSpan={4}
          />
        </TableRow>*/}
        <TableRow>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={3}
          />
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={2}
          />
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={2}
          >
            Caixarias
          </StyledTableCell>

          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            PP(KG)
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Qtde
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Qtde
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Qtde
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            colSpan={4}
            rowSpan={2}
          />
        </TableRow>
        <TableRow>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {pp}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {unidadesCaixariaNivel1}
          </StyledTableCell>

          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {unidadesCaixariaNivel2}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {unidadesCaixariaNivel3}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            Descrição
          </StyledTableCell>

          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            KG
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            UN
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            CX1
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            CX2
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            CX3
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            Total
          </StyledTableCell>
          {dadosComplementaresNota ? (
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: color,
                fontWeight: 'bold',
                fontSize: '20px',
                color: corLetra,
              }}
            >
              NFe
            </StyledTableCell>
          ) : null}
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            % Compra
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            % Venda
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* <StyledTableCell
          align="left"
          style={{
            backgroundColor: color,
            fontWeight: 'bold',
            fontSize: '20px',
            color: corLetra,
            display: 'flex',
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              writingMode: 'vertical-rl',
              transform: 'rotate(180deg)',
            }}
          >
            CODINOME
          </span>
        </StyledTableCell> */}
        <GrupoRowKpi
          label="Venda"
          dataRows={[
            {
              name: 'preco_venda',
              label: 'Preço de Venda Precificado Agora',
              valor: precoVendaPrecificado,
              valorNota: null,
              isQtde: false,
              color: 'yellow',
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'preco_venda_anterior',
              label: 'Preço Anterior de Venda',
              valor: precoAnteriorVenda,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'preco_jornal_ofertas',
              label: 'Preço no Jornal de Ofertas',
              valor: ofertaJornal,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'preço_oferta_zerar_estoque',
              label: 'Preço de Venda em Oferta de Zerar Estoque',
              valor: ofertaZerarEstoque,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          label="Lucro"
          dataRows={[
            {
              name: 'margem_contribuicao',
              label: 'Margem de Contribuição',
              valor: margemContribuicao,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'margem_lucro_bruto',
              label: 'Margem de Lucro Bruto (R$)',
              valor: margemLucroBruto,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          label="Entrada"
          dataRows={[
            {
              name: 'valor_negociado_com_a_industria',
              label: 'Preço Negociado com a Indústria',
              valor: precoCompra,
              valorNota: dadosComplementaresNota
                ? `${formatMoeda(dadosComplementaresNota.vUnCom)} / ${
                    dadosComplementaresNota.uCom
                  }`
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'quantidade_comprada',
              label: 'Quantidade Comprada',
              valor: quantidade,
              valorNota: dadosComplementaresNota
                ? `${dadosComplementaresNota.qCom} ${dadosComplementaresNota.uCom}`
                : null,
              isQtde: true,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'custo_final_produto',
              label: 'Custo Final do Produto',
              valor: custoFinalProduto,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          label="Repasses"
          dataRows={[
            {
              name: 'repasse_ipi',
              label: 'Repasse de IPI',
              valor: repasseDoIpi,
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vIPI
                  ? `${formatMoeda(dadosComplementaresNota.vIPI)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'repasse_st',
              label: 'Repasse de Substituição Tributária',
              valor: repasseDeSt,
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vICMSST
                  ? `${formatMoeda(dadosComplementaresNota.vICMSST)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'repasse_outras_despesas',
              label: 'Repasse de Outras Despesas',
              valor: repasseDeOutrasDespesas,
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vOutro
                  ? `${formatMoeda(dadosComplementaresNota.vOutro)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'repasse_frete_unitario',
              label: 'Repasse de Frete Unitário',
              valor: repasseDoFreteUnitario,
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vFrete
                  ? `${formatMoeda(dadosComplementaresNota.vFrete)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'repasse_seguros',
              label: 'Repasse de Seguros',
              valor: repasseDeSeguros,
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vSeg
                  ? `${formatMoeda(dadosComplementaresNota.vSeg)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
      </TableBody>
    </Table>
  );
}
